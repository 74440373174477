.header {
    text-align: center;
  }
  
  .blink_me {
  animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
  50% {
    opacity: .25;
  }
  }